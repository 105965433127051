<template>
    <modal ref="modalEnviarPedido" titulo="Enviar pedido a otro turno" tamano="modal-lg" no-aceptar adicional="Enviar">
        <div class="row mx-0 py-3" />
        <div class="row mx-0 justify-center">
            <div class="col-4 d-middle-center">
                <input v-model="turno" :value="1" type="radio" class="option-input black radio" />
                <span class="ml-2 text-general">A un turno existente</span>
            </div>
            <div class="col-4 d-middle-center">
                <input v-model="turno" :value="2" type="radio" class="option-input black radio" />
                <span class="ml-2 text-general">A un turno nuevo</span>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0 justify-center">
            <div class="col-7">
                <p class="pl-3 text-general f-14">Seleccionar turno</p>
                <el-select v-model="value" class="w-100">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-7 pl-4">
                <p class="f-14 text-general my-3 mt-4">Información del pedido seleccionado</p>
                <div class="row mx-0">
                    <i class="icon-pedidos text-general f-20" />
                    <div class="col-3 text-general">
                        <p class="f-15 f-400">
                            47
                        </p>
                        <p class="f-15">
                            Pedidos
                        </p>
                    </div>
                    <div class="col-5 f-15 text-general">
                        <p>Peso: 1000 kg</p>
                        <p>Volumen: 10 m3</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            turno: 1,
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: ''
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEnviarPedido.toggle();
        }
    }
}
</script>